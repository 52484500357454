
body{
  background-color: #154881;
  color:#FFFFFF;
}


#footer{
  position: fixed;
  bottom:0px;
  margin:auto;
}

.App {
  text-align: center;
}

.App-logo {
  /*height: 10vmin;*/
  max-width: 700px;
  pointer-events: none;
}

.App-header {
  margin:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.pad10{
  padding-bottom: 10px;
}

.form_input{
  line-height: 20px;
  border: none;
  width:200px;
  border-radius: 10px;
  margin:2px;
  padding:10px;

}

.payment_link{
  color:#FFFFFF;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.3s;
   user-select: none;

}


.progress_snippit{
  padding-top: 150px;
  padding-bottom: 20px;

}

.payment_container{
  margin:auto;
  max-width: 991px;
  margin-top: 20px;
}

.payment_frame{
  width: 100%;
  background: #FFFFFF;

  border-radius: 10px;
  border: none;
  height:800px;
}

.payment_link:hover{
  color:#154881;
}

.payment_link:hover div{
background:#FFFFFF;
}


.payment_link >div{
  width:200px;
  margin: auto;
  border:1px solid white;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
}
